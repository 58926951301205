<template>
	<div>
		<!-- eslint-disable -->
		<BackButton :route="{ name: 'content-accordions', label: 'Accordions' }" />

		<div class="md:flex md:items-center md:justify-between my-5">
			<div class="flex-1 min-w-0">
				<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
					Edit Accordion:
					<span class="ml-4 text-blue-500">
						{{ name }}
					</span>
				</h2>
			</div>
		</div>

		<div class="bg-white border-t border-gray-200 shadow sm:rounded-lg">
			<div class="px-6 py-5">
				<div>
					<FieldsInput
						v-model="name"
						:field="{
							type: 'input',
							inputType: 'text',
							label: 'Accordion Name',
							model: 'name',
							required: true,
							colSpan: 'col-span-6',
						}"
					/>
				</div>

				<div class="flex gap-x-8 mt-8">
					<div class="flex flex-col gap-x-8">
						<FieldsInput
							v-model="term"
							:field="{
								type: 'input',
								inputType: 'text',
								label: 'Question',
								model: 'term',
								required: false,
								colSpan: 'col-span-5',
							}"
						/>

						<FieldsTipTapEditor
							v-model="description"
							:field="{
								label: 'Answer',
								model: 'description',
								required: false,
								colSpan: 'col-span-5',
								rows: 10,
							}"
						/>

						<div class="col-span-2 my-auto w-full flex flex-col gap-y-5 mt-5">
							<div class="flex gap-x-5">
								<button
									v-if="editing"
									type="button"
									class="relative inline-block w-full items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none"
									@click="cancelEdit"
								>
									Cancel
								</button>

								<button
									v-if="editing"
									type="button"
									class="relative flex w-full items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none disabled:opacity-50"
									:disabled="!accordionItemFormValid"
									@click="updateAccordionItem"
								>
									<SaveIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
									Update Accordion Item
								</button>
							</div>

							<button
								v-if="!editing"
								type="button"
								class="relative flex w-full items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none disabled:opacity-50"
								:disabled="!accordionItemFormValid"
								@click="addItem"
							>
								<PlusIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
								Add Accordion Item
							</button>
						</div>
					</div>

					<draggable
						v-model="descriptions"
						handle=".handle"
						item-key="term"
						class="flex-1 mt-8"
						@start="drag = true"
						@end="drag = false"
						@change="itemMoved"
					>
						<template #item="{ element, index }">
							<div class="bg-gray-50 shadow mb-2">
								<div class="flex gap-x-8 items-center px-4 py-4 sm:px-6">
									<div class="min-w-0 flex-1">
										<div v-html="element.term" class="font-semibold text-lg" />
										<div v-html="element.description" />
									</div>

									<div
										class="py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center gap-x-8"
									>
										<button
											type="button"
											class="relative inline-flex items-center p-1 border border-transparent shadow-sm font-medium rounded-md bg-red-100 text-red-600 hover:bg-red-200 focus:outline-none"
											@click="confirmDelete(index)"
										>
											<XIcon class="h-5 w-5" aria-hidden="true" />
										</button>
										<button
											type="button"
											class="relative inline-flex items-center p-1 border border-transparent shadow-sm font-medium rounded-md bg-yellow-200 text-yellow-600 hover:bg-yellow-300 focus:outline-none"
											@click="editAccordionItem(index)"
										>
											<PencilIcon class="h-5 w-5" aria-hidden="true" />
										</button>
										<div class="flex-none flex justify-end items-center">
											<SwitchVerticalIcon
												class="h-5 w-5 text-gray-400 handle"
												aria-hidden="true"
											/>
										</div>
									</div>
								</div>
							</div>
						</template>
					</draggable>
				</div>
			</div>

			<div class="border-t border-gray-200 p-5 flex justify-end">
				<button
					type="button"
					class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 disabled:opacity-50"
					:disabled="!formValid"
					@click="update"
				>
					<SaveIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
					Save
				</button>
			</div>
		</div>

		<ModalsConfirmation
			ref="confirmation"
			:open="confirmationOpen"
			:confirmation-config="confirmationConfig"
			@closeModal="closeModal"
			@confirm="confirm"
		/>
	</div>
</template>

<script>
import { provide, inject, computed, ref } from 'vue'
import { useToast } from 'vue-toastification'

import draggable from 'vuedraggable'
import { PlusIcon, XIcon, PencilIcon, SwitchVerticalIcon, SaveIcon } from '@heroicons/vue/solid'

export default {
	components: {
		draggable,
		PlusIcon,
		XIcon,
		PencilIcon,
		SwitchVerticalIcon,
		SaveIcon,
	},
	setup() {
		const api = inject('api')
		const toast = useToast()

		return {
			api,
			toast,
		}
	},
	data() {
		return {
			id: this.$route.params.id || null,
			drag: false,
			descriptions: [],
			index: null,
			name: null,
			term: null,
			description: null,
			editing: false,
			confirmationOpen: false,
			confirmationConfig: {},
		}
	},
	computed: {
		accordionItemFormValid() {
			return this.term && this.description
		},
		formValid() {
			return this.name && this.descriptions.length
		},
	},
	watch: {
		term: function(val) {
			if (this.term) {
				this.term = this.term.replaceAll('<p class="mb-2"></p>', '')
				setTimeout(() => {
					if (this.term == '') {
						this.term = null
					}
				}, 50)
			}
		},
		description: function(val) {
			if (this.description) {
				this.description = this.description.replaceAll('<p class="mb-2"></p>', '')
				setTimeout(() => {
					if (this.description == '') {
						this.description = null
					}
				}, 50)
			}
		},
	},
	mounted() {
		this.fetchPage()
	},
	methods: {
		fetchPage() {
			this.api.accordions
				.fetch(this.id)
				.then(accordion => {
					this.name = accordion.name
					this.descriptions = accordion.descriptions
				})
				.catch(error => {
					this.toast.error('Error fetching Accordion:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		created(slug) {
			this.$router.push({ name: 'content-accordions-id', params: { id: slug } })
		},

		addItem() {
			this.descriptions.push({
				term: this.term,
				description: this.description,
			})

			setTimeout(() => {
				this.term = null
				this.description = null
			}, 50)
		},

		editAccordionItem(index) {
			this.editing = true
			let term = JSON.parse(JSON.stringify(this.descriptions[index]))
			this.index = index
			this.term = term.term
			this.description = term.description
		},

		updateAccordionItem() {
			this.descriptions[this.index].term = this.term
			this.descriptions[this.index].description = this.description

			this.cancelEdit()
		},

		cancelEdit() {
			this.index = null
			this.term = null
			this.description = null
			this.editing = false
		},

		update() {
			this.loaderShow()

			this.api.accordions
				.slug(this.id)
				.update({
					id: this.id,
					name: this.name,
					descriptions: this.descriptions,
				})
				.then(accordion => {
					this.name = accordion.name
					this.descriptions = accordion.descriptions

					this.toast.success('Successfully update Accordion')
				})
				.catch(error => {
					this.toast.error('Error updating Accordion:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		itemMoved(evt) {
			// let ids = []
			// this.media.images.forEach(element => {
			// 	ids.push(element.id)
			// })
			// console.log(ids)
			// this.loaderShow()
			// this.api.departments
			// 	.slug(this.route.params.id)
			// 	.images.reorder(ids)
			// 	.then(data => {
			// 		this.media.images = []
			// 		data.forEach(element => {
			// 			this.media.images.push(element)
			// 		})
			// 		this.toast.success('Successfully reordered Department Images')
			// 	})
			// 	.catch(error => {
			// 		this.toast.error('Error reordering Department Images:\n' + error.response.data.message)
			// 	})
			// 	.then(() => {
			// 		this.loaderHide()
			// 	})
		},

		closeModal() {
			this.confirmationOpen = false
			this.confirmationConfig = {}
			delete this.$refs.confirmation.category
			delete this.$refs.confirmation.id
		},

		confirm(callback) {
			this[callback]()
		},

		confirmDelete(index) {
			this.confirmationOpen = true
			this.confirmationConfig = {
				callback: 'deleteAccordionItem',
				title: 'Delete Accordion Item',
				content: 'Are you sure you want to delete this Accordion item?',
			}
			this.$refs.confirmation.index = index
		},

		deleteAccordionItem() {
			let index = this.$refs.confirmation.index
			delete this.$refs.confirmation.index

			this.descriptions.splice(index, 1)

			this.closeModal()
		},
	},
}
</script>
